import get from 'lodash/get'
import set from 'lodash/set'

function useLocalStorage() {
  /**
   * Sets a value in localStorage
   * @param {string} localStorageKey - The key to use for the localStorage item
   * @param {string} itemPath - The path to use for the localStorage item
   * @param {any} value - The value to set
   * @example
   * setValue('formRequest', 'body', { name: 'John Doe' })
   */
  const setValue = (localStorageKey, itemPath, value) => {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(set(JSON.parse(localStorage.getItem(localStorageKey)) || {}, itemPath, value))
    )
  }

  /**
   * Gets a value from localStorage
   * @param {string} localStorageKey - The key to use for the localStorage item
   * @param {string} itemPath - The path to use for the localStorage item
   * @example
   * getValue('formRequest', 'body')
   * @returns {any}
   */
  const getValue = (localStorageKey, itemPath) => {
    return get(JSON.parse(localStorage.getItem(localStorageKey)) || {}, itemPath)
  }

  return { setValue, getValue }
}

export { useLocalStorage }
