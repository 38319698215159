import { mapActions, mapGetters } from 'vuex'
import { CONTEXT_API_2FA, DATA_LOCAL_STORAGE, REASONS_FORM, SLUG } from '~/enum/form/constants'
import { PRODUCER_REFUND } from '~/enum/form/producerRefundReasons'
import { REFUND } from '~/enum/form/refundReasons'
import { SPECIFICATION } from '~/enum/form/specification'
import { SUBJECT } from '~/enum/form/subject'
import { operationalSystem } from '~/enum/options'
import abtest from '~/mixins/abtest'
import request2fa from '~/mixins/request2fa'
import tools_form from '~/mixins/tools-form'
import trackAiUse from '~/mixins/track-ai-use'
import user from '~/mixins/user'
import { localeConversion } from '~/utils/sharedHelpers'
import { endpoints, fieldValues } from './utils'

export default {
  mixins: [user, request2fa, tools_form, trackAiUse, abtest],

  created() {
    this.fieldValue['hotlink'] = this.renderHotlink(this) ? this.getDataLocalStorage(DATA_LOCAL_STORAGE.HOTLINK) : ''
    this.fieldValue['operationalSystem'] = [REASONS_FORM.SPARKLE_FORM].includes(this.reason)
      ? this.getDataLocalStorage(DATA_LOCAL_STORAGE.OPERATIONAL_SYSTEM)
      : ''
    this.fieldValue['howDidYouKnow'] = [REASONS_FORM.NEW_USER_FORM].includes(this.reason)
      ? this.getDataLocalStorage(DATA_LOCAL_STORAGE.HOW_DID_YOU_KNOW)
      : ''
    this.fieldValue['productId'] = [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(this.reason)
      ? this.getDataLocalStorage(DATA_LOCAL_STORAGE.ID_PRODUCT)
      : ''
    this.fieldValue['country'] = [REASONS_FORM.DATA_PRIVACY_FORM].includes(this.reason)
      ? ''
      : this.getDataLocalStorage(DATA_LOCAL_STORAGE.COUNTRY)
    this.fieldValue['language'] = this.getDataLocalStorage(DATA_LOCAL_STORAGE.LANGUAGE)
  },
  beforeUpdate() {
    this.saveStorage(this)
    if (this.userProfile) {
      const { languageSelect, country } = localeConversion(this.userProfile?.locale)
      this.updateLanguage(languageSelect !== this.fieldValue['language'] ? this.fieldValue['language'] : languageSelect)
      this.updateCountry(
        country !== this.fieldValue['country'] && this.fieldValue['country'] ? this.fieldValue['country'] : country
      )
    }
  },
  computed: {
    ...mapGetters('casUser', ['userToken', 'userProfile']),
    ...mapGetters('ticketFormStepNavigation', ['currentStepIndex', 'hasFieldsInStep']),
    ...mapGetters('ticketFormAISummary', ['shouldRenderAISummary']),
    ...mapGetters('ticketFormChangeOwnerShip', ['shouldRenderChangeOwnership', 'changeOwnershipPersona']),

    renderSpecification() {
      return this.fieldValue['optionsSpecification'].length > 0
    },
    currentLanguage() {
      const language = this.userProfile?.locale || this.$i18n.locale

      return language
    },
    submitButtonDisabled() {
      return this.shouldRenderChangeOwnership ? !this.$refs.step4?.canSubmit : this.$refs.confirmationModal?.isOpen
    },
  },
  watch: {
    reason: function () {
      this.fieldValue['subjectSelect'] = ''
      this.fieldValue['specificationSelect'] = ''
      this.fieldValue['refundReasonSelect'] = ''
      this.fieldValue['refundProducerReasonSelect'] = ''
      this.fieldValue['optionsSubjects'] = []
      this.fieldValue['optionsSpecification'] = []
      this.fieldValue['optionsRefundReason'] = []
      this.fieldValue['optionsProducerRefundReason'] = []

      this.getSubjects()
    },
    fieldValue: {
      handler: function () {
        this.setForm(this.$refs.form)
        this.renderChatBot(this)
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$watch(
      () => [this.fieldValue.country, this.$route.query],
      async ([country, { subject, reason, specification }]) => {
        const currentFlowParams = { subject, reason, specification }

        const specifications = {
          CNPJ_TO_CNPJ: SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug,
          CPF_TO_CNPJ: SPECIFICATION.CHANGE_REGISTRATION.slug,
          CNPJ_TO_CPF: SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug,
        }

        const changeOwnershipFlows = {
          subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
          reason: [
            SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
            SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
          ],
          specification: Object.values(specifications),
        }

        const isChangeOwnershipFlow = ['subject', 'reason', 'specification'].every(param =>
          changeOwnershipFlows[param].includes(currentFlowParams[param])
        )

        const changeOwnershipPersonaByFlow = {
          [specifications.CNPJ_TO_CNPJ]: 'Company-Company',
          [specifications.CPF_TO_CNPJ]: 'Person-Company',
          [specifications.CNPJ_TO_CPF]: 'Company-Person',
        }

        if (country === 'country_brazil' && isChangeOwnershipFlow) {
          const changeOwnershipPersona = changeOwnershipPersonaByFlow[specification]
          this.setChangeOwnershipPersona(changeOwnershipPersona)
          this.setShouldRenderChangeOwnerShip(true)
          dataLayer.push({
            event: 'custom_event',
            custom_event_name: 'change_ownership',
            event_details: `open::InitChangeOwnership${changeOwnershipPersona}`,
          })
          return
        }
        this.setShouldRenderChangeOwnerShip(false)
      },
      {
        immediate: true,
      }
    )
    const { reason: reasonQuery, specification: specificationQuery } = this.$route.query
    const { subjects } = this.toExtractReason()
    if (reasonQuery) {
      const FILTER_SUBJECTS = subjects.find(({ slug }) => slug === reasonQuery)
      if (FILTER_SUBJECTS) {
        const { specifications, tag: reasonTag } = FILTER_SUBJECTS
        this.fieldValue['subjectSelect'] = reasonTag
        if (specifications) {
          this.getSubjectSpecifications()
          if (specificationQuery) {
            const { tag: specificationTag } = specifications?.find(({ slug }) => slug === specificationQuery)
            this.fieldValue['specificationSelect'] = specificationTag
          }
        }
      }
    }
    this.fieldValue['optionsSubjects'] = subjects.map(({ tag, key }) => {
      return {
        id: tag,
        text: this.$t(`ticket.subject.options.${key}`),
      }
    })
    this.fieldValue['optionsRefundReason'] = REFUND.map(({ tag, key }) => {
      return {
        id: tag,
        text: this.$t(`ticket.refundReason.options.${key}`),
      }
    })
    this.fieldValue['optionsProducerRefundReason'] = PRODUCER_REFUND.map(({ tag, key }) => {
      return {
        id: tag,
        text: this.$t(`ticket.producerRefundReason.options.${key}`),
      }
    })
  },
  methods: {
    ...mapActions('ticketFormStepNavigation', ['setForm']),
    ...mapActions('ticketFormChangeOwnerShip', ['setShouldRenderChangeOwnerShip', 'setChangeOwnershipPersona']),
    getSubjects() {
      const { subjects } = this.toExtractReason()

      this.fieldValue['optionsSubjects'] = subjects.map(({ tag, key }) => {
        return {
          id: tag,
          text: this.$t(`ticket.subject.options.${key}`),
        }
      })
    },
    getSubjectSpecifications() {
      const { subjects } = this.toExtractReason()
      const options = subjects.find(({ tag }) => tag === this.fieldValue['subjectSelect'])

      if (options?.specifications) {
        this.fieldValue['optionsSpecification'] = options.specifications.map(({ tag, key }) => {
          return {
            id: tag,
            text: this.$t(`ticket.subject.specifications.options.${key}`),
          }
        })
        this.fieldValue['optionsSpecification'] = options.specifications.map(({ tag, key }) => ({
          id: tag,
          text: this.$t(`ticket.subject.specifications.options.${key}`),
        }))
      } else {
        this.fieldValue['optionsSpecification'] = []
        this.fieldValue['specificationSelect'] = ''
      }
    },
    replaceQueryRouter(query) {
      delete query._gl

      this.$router.replace({
        query,
      })
    },
    updateSubject(newSubject) {
      const { subjects } = this.toExtractReason()
      const { slug, specifications } = subjects.find(({ tag }) => tag === newSubject)
      const { subject, reason, specification: specificationSlug } = this.$route.query

      const query = !this.fieldValue['subjectSelect']
        ? { ...this.$route.query, reason: slug }
        : { subject, reason: slug }
      this.fieldValue['subjectSelect'] = newSubject
      this.replaceQueryRouter(query)
      this.getSubjectSpecifications()
    },
    updateSpecification(newSpecification) {
      const { subjects } = this.toExtractReason()
      const { specifications } = subjects.find(({ tag }) => tag === this.fieldValue['subjectSelect'])
      const { specification: specificationSlug } = this.$route.query
      const { slug } = specifications.find(({ tag }) => tag === newSpecification)
      const query = { ...this.$route.query, specification: slug }
      this.fieldValue['specificationSelect'] = newSpecification
      this.replaceQueryRouter(query)
    },
    updateRefundReason(newRefundReason) {
      this.fieldValue['refundReasonSelect'] = newRefundReason
    },
    updateProducerRefundReason(newRefundReason) {
      this.fieldValue['producerRefundReasonSelect'] = newRefundReason
    },
    updateTransactions(transactions) {
      this.fieldValue['transactionCode'] = transactions
    },
    updateOperationalSystem(newSystem) {
      this.fieldValue['operationalSystem'] = newSystem
    },
    updateLanguage(newLanguage) {
      this.fieldValue['language'] = newLanguage
    },
    updateAward(newAward) {
      this.fieldValue['awardsAchieved'] = newAward
    },
    updateTool(newTool) {
      this.fieldValue['tool'] = newTool
    },
    updateCredentialsType(newCredential) {
      this.fieldValue['credentialsType'] = newCredential
    },
    updateLeads(newLeads) {
      this.fieldValue['leadCollect'] = newLeads
    },
    updateCountry(newCountry) {
      this.fieldValue['country'] = newCountry
    },
    updateBonusContent(newBonusContent) {
      this.fieldValue['bonusContent'] = newBonusContent
    },
    updateCountryPrivacy(newCountry) {
      this.fieldValue['countryPrivacy'] = newCountry
      this.fieldValue['region'] = null
    },
    updateRegion(newRegion) {
      this.fieldValue['region'] = newRegion
    },
    updateDestinationCountry(newDestinationCountry) {
      this.fieldValue['destinationCountry'] = newDestinationCountry
    },
    onChangeDate(event) {
      const data = event.detail.dateFormatted
      const [day, month, year] = data.split('/')

      const formatData = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2)
      this.fieldValue['birthDate'] = formatData
    },
    updateFile(event) {
      // 5MB
      const LIMIT = 5 * 10 ** 6
      let totalUpload = 0
      const files = event.target.files

      if (files.length) {
        Object.keys(files).map((file, index) => {
          return (totalUpload += files[index].size)
        })
        if (totalUpload < LIMIT) {
          this.fieldValue['attachmentsValid'] = true
          this.attachments = [...this.attachments, ...event.target.files]

          const attachmentsMap = this.attachments.reduce((acc, cur) => {
            let newFiles = files
            if (this.attachmentsMap[event.target.id]?.length > 0) {
              newFiles = [...this.attachmentsMap[event.target.id], ...files]
            }

            return { ...this.attachmentsMap, [event.target.id]: newFiles }
          }, {})

          this.attachmentsMap = attachmentsMap
        } else {
          this.attachments = []
          this.fieldValue['attachmentsValid'] = false
        }
        setTimeout(() => {
          this.fieldValue['attachmentsValid'] = true
        }, 3000)
      }
    },
    updateDevice(newDevice) {
      this.fieldValue['device'] = newDevice
    },
    updateImportReason(newReason) {
      this.fieldValue['importReason'] = newReason
    },
    updateAccessType(newAccessType) {
      this.fieldValue['accessType'] = newAccessType
    },
    updateRequestReason(newReason) {
      this.fieldValue['changeRequestReason'] = newReason
    },
    updateBraceletsNumber(bracelet) {
      this.fieldValue['braceletsNumber'] = bracelet
    },
    updateProductIds(productId) {
      this.fieldValue.productIds.push(productId)
    },
    removeFile(index, item) {
      const newFileList = Array.from(this.attachmentsMap[item])
      newFileList.splice(index, 1)
      this.attachmentsMap[item] = newFileList
      this.attachments = Object.values(this.attachmentsMap)
        .map(fileList => Array.from(fileList))
        .flat()
    },
    getOperationalSystem() {
      return operationalSystem.map(system => ({
        id: system === 'ios' ? 'ios_sparkle' : 'android_sparkle',
        text: this.$t(`ticket.complementaryData.fields.operatingSystem.${system}`),
      }))
    },
    countdownTimer() {
      if (this.fieldValue['buttonReceiveCode']) return
      this.fieldValue['buttonReceiveCode'] = true
      const downloadTimer = setInterval(() => {
        if (this.fieldValue['countdown'] <= 0) {
          clearInterval(downloadTimer)
          this.fieldValue['buttonReceiveCode'] = false
        }
        this.fieldValue['countdown'] -= 1
      }, 1000)
    },
    sendTicket() {
      this.fieldValue['isLoading'] = !this.fieldValue['isLoading']
      this.fieldValue['feedback2fa'] = ''
      if (this.generate2FA(this)) {
        this.codeGenerator()
      } else {
        this.sendDataForm()
      }
    },
    selectProfile: function (profile) {
      this.fieldValue['profile'] = profile
    },
    async codeGenerator() {
      try {
        this.$refs.confirmationModal?.openModal()
        this.fieldValue['isLoading'] = true
        this.fieldValue['countdown'] = this.fieldValue['secondsLimit']
        this.countdownTimer()
        const body = {
          context: CONTEXT_API_2FA,
          locale: this.$i18n.locale,
          userEmail: this.$refs['step2-requesterEmail']?.value,
        }
        await this.$forms.codeGenerator(body)
        this.fieldValue['isLoading'] = !this.fieldValue['isLoading']
      } catch (error) {
        this.fieldValue['feedback2fa'] = this.$t('error.errorSending')
      }
    },
    async sendDataForm() {
      try {
        this.fieldValue['isLoading'] = true
        const subjectLabel = this.fieldValue['optionsSubjects'].find(
          ({ id }) => id === this.fieldValue['subjectSelect']
        ).text
        const specificationLabel = this.fieldValue['specificationSelect']
          ? this.fieldValue['optionsSpecification'].find(({ id }) => id === this.fieldValue['specificationSelect']).text
          : ''
        const languageSelect =
          this.fieldValue['language'] === 'portuguesBr' ? 'portugues_br' : this.fieldValue['language']
        const formData = new FormData()
        formData.append('context', CONTEXT_API_2FA)
        this.$refs.confirmationModal?.value2fa &&
          formData.append('token', this.$refs.confirmationModal?.value2fa.substr(0, 6))
        formData.append('formType', this.reason)
        this.fieldValue['subjectSelect'] && formData.append('contactReason', this.fieldValue['subjectSelect'])
        this.fieldValue['subjectSelect'] &&
          formData.append('subject', `${subjectLabel} ${specificationLabel && `(${specificationLabel})`}`)
        this.fieldValue['specificationSelect'] &&
          formData.append('specificContactReason', this.fieldValue['specificationSelect'])
        this.fieldValue['refundReasonSelect'] &&
          formData.append('reasonBuyerRequestingRefund', this.fieldValue['refundReasonSelect'])
        this.fieldValue['producerRefundReasonSelect'] &&
          formData.append('reasonProducerRequestingRefund', this.fieldValue['producerRefundReasonSelect'])
        const transaction = this.fieldValue['transactionCode'] || this.fieldValue['transactionCodeText']
        transaction &&
          formData.append('transaction', this.fieldValue['transactionCode'] || this.fieldValue['transactionCodeText'])
        const sendPurchaseEmail = this.fieldValue['purchaseEmail'] && !this.fieldValue['purchaseEmailSameAbove']
        sendPurchaseEmail && formData.append('purchaseEmail', this.fieldValue['purchaseEmail'])
        this.fieldValue['productId'] = this.fieldValue['productId']?.replace(/[^0-9]/g, '')
        this.fieldValue['creditCardFirstSixDigits'] = this.fieldValue['creditCardFirstSixDigits']?.replace(
          /[^0-9]/g,
          ''
        )
        this.fieldValue['creditCardLastFourDigits'] = this.fieldValue['creditCardLastFourDigits']?.replace(
          /[^0-9]/g,
          ''
        )

        this.fieldValues.forEach(field => {
          if (this.fieldValue[field]) {
            formData.append(field, this.fieldValue[field])
          }
        })

        if (this.fieldValue['productIds'].length > 0) {
          formData.set('productId', this.fieldValue['productIds'].join(','))
          this.fieldValue['productIds'] = []
          this.fieldValue['productId'] = ''
        }

        for (let i = 0; i < this.attachments.length; i++) {
          formData.append('attachments', this.attachments[i])
        }

        this.fieldValue['language'] && formData.append('language', `idioma_${languageSelect}`)

        Object.entries(this.getAiInterventions()).forEach(([key, value]) => {
          formData.append(key, value)
        })
        this.$refs['step2-body']?.updateFormData(formData)
        this.$refs['step2-requesterEmail']?.updateFormData(formData)
        this.$refs['step2-requesterName']?.updateFormData(formData)

        this.$refs['step3-affiliateName']?.updateFormData(formData)
        this.$refs['step3-backDocumentFiles']?.updateFormData(formData)
        this.$refs['step3-cpf']?.updateFormData(formData)
        this.$refs['step3-currentDocumentFiles']?.updateFormData(formData)
        this.$refs['step3-currentPartnersForm']?.updateFormData(formData)
        this.$refs['step3-frontDocumentFiles']?.updateFormData(formData)
        this.$refs['step3-newCnpj']?.updateFormData(formData)
        this.$refs['step3-newDocumentFiles']?.updateFormData(formData)
        this.$refs['step3-producerName']?.updateFormData(formData)
        this.$refs['step3-selfieDocumentFiles']?.updateFormData(formData)
        this.$refs['step3-cnpj']?.updateFormData(formData)
        this.$refs['step3-newCpf']?.updateFormData(formData)
        this.$refs['step3-userNewEmail']?.updateFormData(formData)
        this.$refs['step3-proofResidenceFiles']?.updateFormData(formData)
        this.$refs['step3-address']?.updateFormData(formData)
        this.$refs['step3-number']?.updateFormData(formData)
        this.$refs['step3-addressComplement']?.updateFormData(formData)

        this.$refs.step4?.updateFormData(formData)

        const endpoint = endpoints[this.reason]
        await this.$forms.postDataPrivacyForm(formData, endpoint)
        this.$refs.confirmationModal?.openModal()
        this.fieldValue['check2fa'] = !this.fieldValue['check2fa']
        this.uploadAttachmentError = false
        if (this.shouldRenderChangeOwnership) this.openSurvicate()
        this.onRequestSent()
      } catch (error) {
        this.errorType = error.response?.data?.internalCode
        const showLink = {
          HP_TRANSACTION_IS_NOT_FROM_PHYSICAL_PRODUCT_TYPE: true,
          INVALID_HP_TRANSACTION: true,
        }
        if (showLink[this.errorType]) {
          this.link = {
            name: 'physicalProducts',
            title: this.$t('client-error-message.default'),
          }
        }
        if (error.response?.data?.internalCode === 'API_HELP_CENTER_09') {
          this.$refs.confirmationModal?.closeModal()
          window.scrollTo(0, 0)
          this.uploadAttachmentError = true

          return
        }
        if (error.response?.status === 500) {
          return (this.fieldValue['feedback2fa'] = this.$t('error.errorSending'))
        }

        return (this.fieldValue['feedback2fa'] = this.$t('error.sendingCode'))
      } finally {
        this.fieldValue['isLoading'] = !this.fieldValue['isLoading']
      }
    },
    onRequestSent() {
      this.clearIaUse()
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_send_form',
        event_details: 'Success::RequestSend',
      })
      if (this.shouldRenderChangeOwnership) {
        dataLayer.push({
          event: 'custom_event',
          custom_event_name: 'change_ownership',
          event_details: `Click::ChangeOwnership${this.changeOwnershipPersona}`,
        })
      }
    },
    showSelfieExampleModal() {
      this.$refs.selfieModal.openModal()
      this.selfieModalOpen = true
    },
    ShowEmancipatedModal() {
      this.$refs.emancipatedModal.openModal()
      this.emancipatedModalOpen = true
    },
    openSurvicate() {
      if (typeof window !== 'undefined' && process.client) {
        const { subject, reason, specification } = this.$route.query
        const currentFlow = [subject, reason, specification].filter(Boolean).join('.')
        const flows = {
          CNPJ_TO_CNPJ: `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug}.${SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug}`,
          CPF_TO_CNPJ: `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug}.${SPECIFICATION.CHANGE_REGISTRATION.slug}`,
          CNPJ_TO_CPF: `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug}.${SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug}`,
        }
        const surveyId = {
          [flows.CNPJ_TO_CNPJ]: 'eaad37344b5a114d',
          [flows.CPF_TO_CNPJ]: 'df5d305d76f28cc4',
          [flows.CNPJ_TO_CPF]: 'a2b9c50db2b4a4eb',
        }[currentFlow]

        if (!surveyId) return

        const options = {
          forceDisplay: true,
          displayMethod: 'delayed',
          displayOptions: {
            delay: 5,
          },
        }
        window._sva.showSurvey(surveyId, options)
      }
    },
    onCallConvertABTest() {
      this.convertABTest('summary-feedback')
    },
  },
  data: () => ({
    fieldValue: {
      attachments: '',
      attachmentsMap: '',
      attachmentsValid: true,
      automatedDecisionReview: '',
      birthDate: '',
      bonusContent: '',
      buttonReceiveCode: false,
      check2fa: true,
      countdown: 0,
      country: '',
      cnpj: '',
      currentDocument: '',
      feedback2fa: '',
      hotlink: '',
      howDidYouKnow: '',
      productId: '',
      productIds: [],
      sourceProductId: '',
      importReason: '',
      productName: '',
      device: '',
      purchaseEmail: '',
      isLoading: false,
      language: '',
      languageSelect: '',
      newCorrectionDocument: '',
      newUpdateDocument: '',
      notificationReceivedDetail: '',
      operationalSystem: '',
      optionsSpecification: [],
      optionsSubjects: [],
      optionsRefundReason: [],
      countryPrivacy: '',
      secondsLimit: 60,
      specificationSelect: '',
      subjectSelect: '',
      refundReasonSelect: '',
      producerRefundReasonSelect: '',
      transactionCode: '',
      transactionCodeText: '',
      subscriptionCode: '',
      region: '',
      destinationCountry: '',
      updateEmailToAllPurchases: false,
      updateEmailInMyHotmartRegistration: false,
      changeRequestReason: '',
      coProducerName: '',
      coProducerEmail: '',
      tool: '',
      testPurchase: false,
      pixelId: '',
      pixelEmail: '',
      communityId: '',
      credentialsType: '',
      acquisitionURL: '',
      integrationEmail: '',
      studentEmail: '',
      accessType: '',
      leadCollect: '',
      shortLink: '',
      automaticEmailProvider: '',
      materialLink: '',
      leadPageLink: '',
      endDate: '',
      startDate: '',
      startOriginalDate: '',
      endOriginalDate: '',
      startNewDate: '',
      endNewDate: '',
      productLink: '',
      checkoutLink: '',
      paymentMethod: '',
      purchaseDate: '',
      billingAmount: '',
      billingDate: '',
      creditCardFirstSixDigits: '',
      creditCardLastFourDigits: '',
      firstTransactionCode: '',
      secondTransactionCode: '',
      awardsAchieved: [],
      phoneNumber: '',
      fullAddress: '',
      addressNeighborhood: '',
      addressStreetCode: '',
      addressCity: '',
      addressState: '',
      deliveryCountry: '',
    },
    attachments: [],
    attachmentsMap: [],
    fieldValues: fieldValues,
    selfieModalOpen: false,
    emancipatedModalOpen: false,
    uploadAttachmentError: false,
    link: '',
    errorType: '',
    typeInfo: 'danger',
  }),
  props: {
    reason: String,
    shouldRenderInSteps: Boolean,
  },
}
