import { computed, ref, useContext, useRoute, useRouter, useStore } from '@nuxtjs/composition-api'
import { useLocalStorage } from '~/composables'


export default {
  __name: 'ConfirmationModal',
  props: {
  isLoading: {
    type: Boolean,
    default: false,
  },
  check2fa: {
    type: Boolean,
    default: false,
  },
  buttonReceiveCode: {
    type: Boolean,
    default: undefined,
  },
  feedback2fa: {
    type: String,
    default: undefined,
  },
  countdown: {
    type: Number,
    default: undefined,
  },
  sendDataForm: {
    type: Function,
    default: () => {},
  },
  sendTicket: {
    type: Function,
    default: () => {},
  },
},
  setup(__props, { expose }) {

const props = __props;



const value2fa = ref('')
const isOpen = ref(false)
const router = useRouter()
const store = useStore()
const locale = useRoute().value.params.locale
const { getValue } = useLocalStorage()
const { $t } = useContext()

const requesterEmail = ref('')

const shouldRenderChangeOwnership = computed(
  () => store.getters['ticketFormChangeOwnerShip/shouldRenderChangeOwnership']
)

const successMessage = computed(() => {
  if (props.check2fa) return `${$t('ticket.descriptiveTextForTokenConfirmation')}${requesterEmail?.value}.`
  if (shouldRenderChangeOwnership) return $t('success.successSendTicketWithEmail', { email: requesterEmail?.value })
  return $t('success.successSendTicket')
})

function openModal() {
  requesterEmail.value = getValue('formRequest', 'requesterEmail')
  isOpen.value = true
}

function closeModal() {
  isOpen.value = false
}

function resetNavigation() {
  return store.dispatch('ticketFormStepNavigation/resetNavigation')
}

function goToHome() {
  resetNavigation()
  router.push({ path: `/${locale}` })
}

function sendDataLayer(origin) {
  if (props.check2fa) return

  const event_details = {
    x: 'Click::CloseXSuccessModal',
    backdrop: 'Click::CloseBackdropSuccessModal',
    'close-button': 'Click::CloseSuccessModal',
  }[origin]

  if (!event_details) return

  window.dataLayer.push({
    event: 'custom_event',
    custom_event_name: 'click_send_form',
    event_details,
  })
}

function handleClose({ origin }) {
  if (isOpen.value) {
    sendDataLayer(origin)
    goToHome()
  }
  closeModal()
}

expose({
  value2fa: computed(() => value2fa.value),
  isOpen: computed(() => isOpen.value),
  openModal,
  closeModal,
})

return { __sfc: true,props, value2fa, isOpen, router, store, locale, getValue, $t, requesterEmail, shouldRenderChangeOwnership, successMessage, openModal, closeModal, resetNavigation, goToHome, sendDataLayer, handleClose }
}

}