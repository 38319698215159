import { render, staticRenderFns } from "./FormRequest.vue?vue&type=template&id=8070c36a&scoped=true"
import script from "./FormRequest.js?vue&type=script&lang=js&external"
export * from "./FormRequest.js?vue&type=script&lang=js&external"
import style0 from "./FormRequest.scss?vue&type=style&index=0&id=8070c36a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8070c36a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcText: require('/drone/src/components/Atoms/Text/Text.vue').default,HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default,HcSelect: require('/drone/src/components/Atoms/Select/Select.vue').default,HcTicketModal: require('/drone/src/components/Molecules/TicketModal/TicketModal.vue').default,HcStep2Body: require('/drone/src/components/Molecules/FormRequest/Step2/Step2-body.vue').default,HcInputFile: require('/drone/src/components/Atoms/InputFile/InputFile.vue').default,HcTagGroup: require('/drone/src/components/Atoms/TagGroup/TagGroup.vue').default,HcFormRequestSummary: require('/drone/src/components/Molecules/FormRequestSummary/FormRequestSummary.vue').default,HcStep3Title: require('/drone/src/components/Molecules/FormRequest/Step3/Step3-title.vue').default,HcInput: require('/drone/src/components/Atoms/Input/Input.vue').default,HcStep3Cpf: require('/drone/src/components/Molecules/FormRequest/Step3/Step3-cpf.vue').default,HcStep3Cnpj: require('/drone/src/components/Molecules/FormRequest/Step3/Step3-cnpj.vue').default,HcStep3Address: require('/drone/src/components/Molecules/FormRequest/Step3/Step3-address.vue').default,HcStep3Number: require('/drone/src/components/Molecules/FormRequest/Step3/Step3-number.vue').default,HcInputMultiple: require('/drone/src/components/Atoms/InputMultiple/InputMultiple.vue').default,HcTextArea: require('/drone/src/components/Atoms/TextArea/TextArea.vue').default,HcStep4: require('/drone/src/components/Molecules/FormRequest/Step4/Step4.vue').default,HcInfoBox: require('/drone/src/components/Atoms/InfoBox/InfoBox.vue').default,HcForm: require('/drone/src/components/Atoms/Form/Form.vue').default,HcConfirmationModal: require('/drone/src/components/Molecules/FormRequest/Modals/ConfirmationModal.vue').default})
