import { computed, onBeforeMount, ref, watch } from '@nuxtjs/composition-api'
import { useLocalStorage } from './useLocalStorage'

/**
 * Syncs a value to localStorage
 * @param {string} path - The path to use for the localStorage item
 * @param {any} initialValue - The initial value to use
 * @example
 * const value = useLocalStorageRef('formRequest.body', '')
 */
const useLocalStorageRef = (path, initialValue) => {
  const { getValue, setValue } = useLocalStorage()
  const localStorageKey = computed(() => path.split('.')[0])
  const itemPath = computed(() => path.split('.').slice(1).join('.'))
  const value = ref(initialValue)

  watch(value, () => {
    setValue(localStorageKey.value, itemPath.value, value.value)
  })

  onBeforeMount(() => {
    const storedValue = getValue(localStorageKey.value, itemPath.value)
    if (storedValue) {
      value.value = storedValue
    }
  })

  return value
}

export { useLocalStorageRef }
